import React from 'react'
import logo from "../assets/logo_white_center.png"

function Main() {
  return (
    <div className='w-full h-screen flex justify-center items-center'>
      <div className='items-center'>
        <img src={ logo } alt="NOTESED" className='h-[200px]'/>
        <p className='font-[apple] text-center'>Not yet, but soon.</p>
      </div>
      
    </div>
  )
}

export default Main
